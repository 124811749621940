import { memo, useEffect, useState, FC } from "react";
import {
    Box,
    Flex,
    FormLabel,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Spinner,
    Stack,
    Td,
    Th,
    Tr,
} from "@chakra-ui/react";
import { OpenSearchErrorFiles } from "../../../types/api/file";
import { TableContent } from "../layout/TableContent";

type Props = {
    ErrorFiles: OpenSearchErrorFiles | undefined;
    isOpen: boolean;
    onClose: () => void;
    loading: boolean;
};

export const CsvImportErrorModal: FC<Props> = memo((props) => {
    const { isOpen, onClose, ErrorFiles, loading } = props;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            autoFocus={false}
            size={"4xl"}
        >
            <ModalOverlay />
            <ModalContent pb={2}>
                <ModalHeader>CSV取込エラー詳細</ModalHeader>
                <ModalCloseButton />
                <ModalBody mx={{ base: 0, md: 6 }}>
                    {loading ? (
                        <Spinner color="teal.500" />
                    ) : (
                        ErrorFiles &&
                        (ErrorFiles["status"] !== 200 ? (
                            <Box key="error">{JSON.stringify(ErrorFiles)}</Box>
                        ) : (
                            <Stack>
                                {ErrorFiles.errorFiles
                                    ?.filter((file) => {
                                        return file.errorCount > 0;
                                    })
                                    .map((file) => (
                                        <Stack key={file.filename}>
                                            <HStack>
                                                <Box key={"head-" + file.filename}>
                                                    {file.filename}
                                                </Box>
                                                <Spacer />
                                                <Box
                                                    color={"red.500"}
                                                    key={"head-count-" + file.filename}
                                                >
                                                    {"エラー：" + file.errorCount + "件"}
                                                </Box>
                                            </HStack>
                                            <TableContent
                                                heightOffset="-100px"
                                                small={true}
                                                headerRows={
                                                    <Tr>
                                                        <Th>エラー行</Th>
                                                        <Th></Th>
                                                        <Th w="100%">エラー内容</Th>
                                                    </Tr>
                                                }
                                                bodyRows={file.errors?.map((err, i) => (
                                                    <Tr key={i} bg={"gray.50"}>
                                                        <Td textAlign={"right"}>
                                                            {String(err.row) + " 行目"}
                                                        </Td>
                                                        <Td>{err.value}</Td>
                                                        <Td>{err.error.errorMessage}</Td>
                                                    </Tr>
                                                ))}
                                            >
                                            </TableContent>
                                            {file.errorCount != file.errors?.length && (
                                                    <FormLabel>{`※${file.errors?.length}件まで表示`}</FormLabel>
                                            )}
                                        </Stack>
                                    ))}
                            </Stack>
                        ))
                    )}
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
});
