import { ChangeEvent, memo, useEffect, useState, FC, useCallback } from "react";
import {
  Badge,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { SecurityLevel_V1Type, SecurityLevelPtn } from "../../../types/api/commonTypes";
import { CloseIcon } from "@chakra-ui/icons";
import { CsvImportDef, CsvColInfo, DATE_FORMAT, DATE_PATTERN } from "../../../types/api/csvImportDef";
import { EntityTypeInfo } from "../../../types/api/tenantTableTypes";

type Props = {
  csvImportDef: CsvImportDef;
  existingIds: string[];
  typeNames: string[];
  tenantEntityTypes: EntityTypeInfo[];
  isOpen: boolean;
  onInsert: (
    name: string,
    id: string,
    securityLevel: SecurityLevel_V1Type,
    ngsiConvert: boolean,
    entityTypeName: string,
    cols: CsvColInfo[]
  ) => void;
  onUpdate: (
    name: string,
    id: string,
    securityLevel: SecurityLevel_V1Type,
    ngsiConvert: boolean,
    entityTypeName: string,
    cols: CsvColInfo[]
  ) => void;
  onDelete: (id: string) => void;
  onClose: () => void;
};

export const CsvImportDefModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, csvImportDef, existingIds, typeNames, tenantEntityTypes } = props;
  const isAddMode: boolean = csvImportDef.definition_id ? false : true;

  const [name, setName] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [securityLevel, setSecurityLevel] = useState<SecurityLevel_V1Type>("");
  const [ngsiConvert, setNgsiConvert] = useState<boolean>(false);
  const [entityTypeName, setEntityTypeName] = useState<string>("");

  // colsにnoを追加した型を定義
  type CsvColInfoWithNo = CsvColInfo & { no: number };
  const [csvColInfoWithNo, setCsvColInfoWithNo] = useState<CsvColInfoWithNo[]>([]);

  // 一時使用する変数
  const [selectColIndex, setSelectColIndex] = useState<number>(0);
  const [bulkColNames, setBulkColNames] = useState<string>("");
  const [bulkColAttributes, setBulkColAttributes] = useState<string>("");

  // 選択可能なセキュリティレベルの定数と表示名称
  const securityLevelOptions = [
    { value: SecurityLevelPtn.Public, label: "IoT(public)" },
    { value: SecurityLevelPtn.Private, label: "IoT(private)" },
    { value: SecurityLevelPtn.Exclusive, label: "IoT(exclusive)" },
    { value: SecurityLevelPtn.Anonymized, label: "匿名加工情報" },
  ];

  // サブモーダル関係
  const {
    isOpen: isDateDetailModalOpen,
    onOpen: onDateDetailModalOpen,
    onClose: onDateDetailModalClose,
  } = useDisclosure();
  const {
    isOpen: isBulkSettingModalOpen,
    onOpen: onBulkSettingModalOpen,
    onClose: onBulkSettingModalClose,
  } = useDisclosure();

  useEffect(() => {
    onModalReset();
  }, [csvImportDef]);

  // 「追加」ボタン押下時
  const onClickCreate = () => {
    // エラーチェックしてエラーの場合は処理を中断
    if (checkError(isAddMode)) return;

    const cols = csvColInfoWithNo
      .sort((a, b) => a.no - b.no)
      .map((col) => {
        const { no, ...rest } = col;
        return rest;
      });

    props.onInsert(name, id, securityLevel, ngsiConvert, entityTypeName, cols);
    props.onClose();
  };

  // 「更新」ボタン押下時
  const onClickUpdate = () => {
    // エラーチェックしてエラーの場合は処理を中断
    if (checkError(isAddMode)) return;

    const cols = csvColInfoWithNo
      .sort((a, b) => a.no - b.no)
      .map((col) => {
        const { no, ...rest } = col;
        return rest;
      });
    // nameとcols 以外は変更せず更新
    props.onUpdate(
      name,
      csvImportDef.id,
      csvImportDef.securityLevel,
      csvImportDef.ngsiConvert,
      csvImportDef.entityTypeName,
      cols
    );
    props.onClose();
  };

  // 「削除」ボタン押下時
  const onClickDelete = () => {
    if (id) {
      // エンティティタイプ設定に使用されている場合は削除不可
      if (tenantEntityTypes.some((entity) => entity.TypeName === csvImportDef.entityTypeName)) {
        alert("エンティティタイプ設定に使用されているため、削除できません。");
        return;
      }

      // 確認メッセージを表示
      if (
        window.confirm(
          `CSV取込定義「${name}（${id}）」を削除しますか？\n※アップロードファイルやデータレイク格納済ファイルは自動削除されません。CSV取込定義を削除する前に手動で削除してください。`
        )
      ) {
        props.onDelete(id);
        props.onClose();
      }
    }
  };

  // エラーチェック＆メッセージ表示用の関数
  const checkError = useCallback(
    (isAddMode: boolean): boolean => {
      // csvColInfoWithNoのnoをソートして振り直す
      const sortedCols = csvColInfoWithNo.sort((a, b) => a.no - b.no).map((col, index) => ({ ...col, no: index + 1 }));
      setCsvColInfoWithNo(sortedCols);

      // 定義IDの形式チェック
      if (!/^[a-z0-9_]{1,50}$/.test(id)) {
        alert("定義ID（テーブル名）は50文字以内の小文字英数字、アンダーバー（_）のみ使用できます。");
        return true;
      }
      // 定義IDの重複チェック　※追加の場合のみ
      if (isAddMode && existingIds.includes(id)) {
        alert("既に登録されている定義ID（テーブル名）です。");
        return true;
      }
      // 定義名の入力チェック
      if (!name) {
        alert("定義名が未入力です。");
        return true;
      }

      // セキュリティレベルのチェック（FIWARE登録ありの場合、IoTのセキュリティレベルのみ）
      const iotSecurityLevels: SecurityLevel_V1Type[] = securityLevelOptions
        .filter((option) => option.label.startsWith("IoT"))
        .map((option) => option.value);
      if (ngsiConvert && !iotSecurityLevels.includes(securityLevel)) {
        const allowedLevels = securityLevelOptions
          .filter((option) => iotSecurityLevels.includes(option.value))
          .map((option) => option.label)
          .join("、");
        alert(`FIWARE登録ありの場合、セキュリティレベルは「${allowedLevels}」のいずれかを選択してください。`);
        return true;
      }

      // エンティティタイプ名の形式チェック
      if (ngsiConvert && !/^[a-zA-Z][a-zA-Z0-9_-]{0,49}$/.test(entityTypeName)) {
        alert(
          "エンティティタイプ名は50文字以内の英数字、ハイフン（-）、アンダーバー（_）のみ使用できます。先頭は英字で始まる必要があります。"
        );
        return true;
      }
      // エンティティタイプ名の重複チェック　※追加の場合のみ
      if (isAddMode && typeNames.some((name) => name === entityTypeName)) {
        alert("既に登録されているエンティティタイプ名です。");
        return true;
      }

      // CSV項目の入力チェック
      if (sortedCols.length === 0) {
        alert("CSV項目を1つ以上設定してください。");
        return true;
      }
      // 列名の形式チェック
      const invalidColName = sortedCols.find((col) => !col.name);
      if (invalidColName) {
        alert("未入力の列名があります。");
        return true;
      }
      // 列名の文字チェック
      const invalidColNameCharacter = sortedCols.find((col) => /[",]/.test(col.name));
      if (invalidColNameCharacter) {
        alert(`列名に「,」や「"」は使用できません。`);
        return true;
      }
      // 列名の重複チェック（大文字小文字問わずチェック）
      const colNames = sortedCols.map((col) => col.name.toLowerCase());
      const duplicateColName = colNames.find((name, index) => colNames.indexOf(name) !== index);
      if (duplicateColName) {
        alert(`列名「${duplicateColName}」が重複しています。`);
        return true;
      }
      // 属性名の形式チェック
      if (ngsiConvert) {
        // 属性名の形式チェック（空文字は許す）
        const invalidColAttribute = sortedCols.find(
          (col) => col.attribute && !/^[a-zA-Z0-9_-]{1,50}$/.test(col.attribute)
        );
        if (invalidColAttribute) {
          alert("属性名は50文字以内の英数字、ハイフン（-）、アンダーバー（_）のみ使用できます。");
          return true;
        }
        // 属性名の重複チェック
        const colAttributes = sortedCols.map((col) => col.attribute).filter(attr => attr !== "");
        const duplicateColAttribute = colAttributes.find((name, index) => colAttributes.indexOf(name) !== index);
        if (duplicateColAttribute) {
          alert(`属性名「${duplicateColAttribute}」が重複しています。`);
          return true;
        }
        // 属性名の「id」チェック。必ず「id」の属性名が必要
        if (!colAttributes.includes("id")) {
          alert("いずれかの属性名に「id」を設定してください。");
          return true;
        }
        // 属性名が「id」の場合の必須フラグのチェック
        if (!sortedCols.find((col) => col.attribute === "id")?.isRequired) {
          alert("属性名「id」は必須項目として設定してください。");
          return true;
        }
        // 属性名が「id」の場合の型のチェック
        if (sortedCols.find((col) => col.attribute === "id")?.type !== "string") {
          alert("属性名「id」の型は「文字列」に設定してください。");
          return true;
        }

        // 属性名の予約語チェック。「type」は予約語
        if (colAttributes.includes("type")) {
          alert("属性名に「type」は使用できません。");
          return true;
        }
      }

      return false;
    },
    [csvColInfoWithNo, id, existingIds, name, ngsiConvert, entityTypeName, typeNames, securityLevelOptions]
  );

  // CSV項目設定の変更
  const onChangeCell = (index: number, key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const _cells = [...csvColInfoWithNo];
    _cells[index] = { ..._cells[index], [key]: event.target.value };
    setCsvColInfoWithNo(_cells);
  };
  const onChangeList = (index: number, key: string) => (event: ChangeEvent<HTMLSelectElement>) => {
    const _cells = [...csvColInfoWithNo];
    _cells[index] = { ..._cells[index], [key]: event.target.value };
    setCsvColInfoWithNo(_cells);
  };
  const onChangeCheck = (index: number, key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const _cells = [...csvColInfoWithNo];
    _cells[index] = { ..._cells[index], [key]: event.target.checked };
    setCsvColInfoWithNo(_cells);
  };
  const onClickListAdd = () => {
    const empty: CsvColInfo = {
      name: "",
      attribute: "",
      type: "string",
      isRequired: false,
      date_estimation: true,
      date_format: "SLASH",
      date_pattern: "Ymd",
    };
    const maxNo = csvColInfoWithNo.length > 0 ? Math.max(...csvColInfoWithNo.map((col) => col.no)) : 0;
    setCsvColInfoWithNo([...csvColInfoWithNo, { ...empty, no: maxNo + 1 }]);
  };

  // モーダル表示のリセット
  const onModalReset = () => {
    if (csvImportDef) {
      setName(csvImportDef.name);
      setId(csvImportDef.id);
      setSecurityLevel(csvImportDef.securityLevel);
      setNgsiConvert(csvImportDef.ngsiConvert);
      setEntityTypeName(csvImportDef.entityTypeName);
      setCsvColInfoWithNo(csvImportDef.cols.map((col, index) => ({ ...col, no: index + 1 })));
    }
  };

  // サブモーダル表示のリセット
  const onSubModalReset = () => {
    setBulkColNames("");
    setBulkColAttributes("");
  };

  const renderBadge = (isRequired: boolean | undefined, isNoChange?: boolean | undefined) => {
    if (isNoChange) {
      return (
        <Badge colorScheme="gray" ml={2}>
          変更不可
        </Badge>
      );
    }
    if (isRequired === true) {
      return (
        <Badge colorScheme="red" ml={2}>
          必須
        </Badge>
      );
    } else if (isRequired === false) {
      return (
        <Badge colorScheme="gray" ml={2}>
          変更不可
        </Badge>
      );
    }
    return null;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onModalReset();
          onClose();
        }}
        motionPreset="slideInBottom"
        autoFocus={false}
        size={"4xl"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent pb={2}>
          <ModalHeader>CSV取込定義詳細</ModalHeader>
          <ModalCloseButton />
          <ModalBody mx={{ base: 0, md: 6 }}>
            <Tabs>
              <TabList>
                <Tab>基本設定</Tab>
                <Tab>項目設定</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing={4}>
                    <FormControl isInvalid={!/^[a-z0-9_]{1,50}$/.test(id)}>
                      <FormLabel>
                        定義ID（テーブル名）
                        {renderBadge(isAddMode)}
                      </FormLabel>
                      <Input value={id} isReadOnly={!isAddMode} onChange={(e) => setId(e.target.value)} />
                      {!/^[a-z0-9_]{1,50}$/.test(id) && (
                        <FormErrorMessage>
                          50文字以内の小文字英数字、アンダーバー（_）のみ使用できます。
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>
                        定義名
                        {renderBadge(true)}
                      </FormLabel>
                      <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>
                        セキュリティレベル
                        {renderBadge(isAddMode)}
                      </FormLabel>
                      <Select
                        value={securityLevel}
                        onChange={(e) => setSecurityLevel(e.target.value as SecurityLevel_V1Type)}
                        pointerEvents={!isAddMode ? "none" : "auto"}
                      >
                        {securityLevelOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <HStack>
                      <FormControl>
                        <FormLabel>
                          FIWARE登録
                          {renderBadge(undefined, !isAddMode)}
                        </FormLabel>
                        <Checkbox
                          isChecked={ngsiConvert}
                          onChange={(e) => {
                            setNgsiConvert(e.target.checked);
                            setEntityTypeName("");
                            setCsvColInfoWithNo(csvColInfoWithNo.map((col) => ({ ...col, attribute: "" })));
                          }}
                          colorScheme="teal"
                          isReadOnly={!isAddMode}
                        >
                          有効
                        </Checkbox>
                      </FormControl>
                      {ngsiConvert && (
                        <FormControl
                          isInvalid={!/^[a-zA-Z][a-zA-Z0-9_-]{0,49}$/.test(entityTypeName)}
                          isReadOnly={!ngsiConvert || !isAddMode}
                        >
                          <FormLabel>
                            エンティティタイプ名
                            {renderBadge(ngsiConvert, !isAddMode)}
                          </FormLabel>
                          <Input value={entityTypeName} onChange={(e) => setEntityTypeName(e.target.value)} />
                          {!/^[a-zA-Z][a-zA-Z0-9_-]{0,49}$/.test(entityTypeName) && (
                            <FormErrorMessage>
                              50文字以内の英数字、ハイフン（-）、アンダーバー（_）のみ使用できます。先頭は英字で始まる必要があります。
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </HStack>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={4}>
                    <HStack>
                      <FormLabel>CSV項目</FormLabel>
                      <Spacer />
                      <PrimaryButton onClick={onBulkSettingModalOpen}>列名一括設定</PrimaryButton>
                      <PrimaryButton onClick={onClickListAdd}>行追加</PrimaryButton>
                    </HStack>
                    <Flex border={"solid"} borderWidth={1} borderColor={"gray.300"}>
                      <TableContainer w="full" h="50vh" overflowY="auto">
                        <Table size={"sm"}>
                          <Thead position="sticky" top={0} zIndex={1} bg="white">
                            <Tr>
                              <Th minWidth="80px">No</Th>
                              <Th w="100vw">列名</Th>
                              {ngsiConvert && <Th w="100vw">属性名 </Th>}
                              <Th minWidth="100px">型</Th>
                              <Th>必須</Th>
                              <Th>日時設定</Th>
                              <Th textAlign={"center"}>削除</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {csvColInfoWithNo.map((cell, i) => (
                              <Tr key={i}>
                                <Td>
                                  <Input
                                    size={"sm"}
                                    type="number"
                                    value={cell.no}
                                    onChange={onChangeCell(i, "no")}
                                    max={999}
                                  />
                                </Td>
                                <Td px={1} py={2}>
                                  <Input size={"sm"} onChange={onChangeCell(i, "name")} value={cell.name} />
                                </Td>
                                {ngsiConvert && (
                                  <Td px={1} py={2}>
                                    <Input
                                      size={"sm"}
                                      onChange={onChangeCell(i, "attribute")}
                                      value={cell.attribute}
                                      isReadOnly={!ngsiConvert}
                                    />
                                  </Td>
                                )}
                                <Td px={1} py={2}>
                                  <Select size={"sm"} value={cell.type} onChange={onChangeList(i, "type")}>
                                    <option value={"string"}>文字列</option>
                                    <option value={"double"}>数値</option>
                                    <option value={"timestamp"}>日時</option>
                                  </Select>
                                </Td>
                                <Td px={1} py={2} textAlign={"center"}>
                                  <Checkbox isChecked={cell.isRequired} onChange={onChangeCheck(i, "isRequired")} />
                                </Td>
                                <Td textAlign={"center"}>
                                  <HStack>
                                    <Button
                                      colorScheme="blue"
                                      size={"sm"}
                                      variant={"outline"}
                                      onClick={() => {
                                        setSelectColIndex(i);
                                        onDateDetailModalOpen();
                                      }}
                                      isDisabled={cell.type !== "timestamp"}
                                    >
                                      詳細
                                    </Button>
                                    {cell.type === "timestamp" && (
                                      <>
                                        {!cell.date_estimation && (
                                          <Stack spacing={1} fontSize="xs">
                                            <Flex>{DATE_FORMAT[cell.date_format]?.displayName}</Flex>
                                            {cell.date_format?.startsWith("ISO") ? null : (
                                              <Flex>{DATE_PATTERN[cell.date_pattern]?.displayName}</Flex>
                                            )}
                                          </Stack>
                                        )}
                                      </>
                                    )}
                                  </HStack>
                                </Td>
                                <Td textAlign={"center"}>
                                  <IconButton
                                    onClick={() => {
                                      const newCols = [...csvColInfoWithNo];
                                      newCols.splice(i, 1);
                                      setCsvColInfoWithNo(newCols);
                                    }}
                                    variant="outline"
                                    colorScheme="red"
                                    aria-label="削除"
                                    size={"xs"}
                                    icon={<CloseIcon />}
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Flex>
                    <Flex fontSize={"sm"} ml="2" color={"gray.700"}>
                      ※No、列名、属性名、型を変更する場合、取込済のファイルを再取込する必要があります。
                    </Flex>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <HStack>
              {isAddMode ? (
                <PrimaryButton onClick={onClickCreate}>追加</PrimaryButton>
              ) : (
                <>
                  <PrimaryButton onClick={onClickUpdate}>更新</PrimaryButton>
                  <PrimaryButton onClick={onClickDelete}>削除</PrimaryButton>
                </>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size={"lg"} isOpen={isDateDetailModalOpen} onClose={onDateDetailModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>日時設定詳細</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {csvColInfoWithNo[selectColIndex] && (
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>日時推測</FormLabel>
                  <Checkbox
                    isChecked={csvColInfoWithNo[selectColIndex].date_estimation ?? false}
                    onChange={onChangeCheck(selectColIndex, "date_estimation")}
                  >
                    有効（フォーマット指定なし）
                  </Checkbox>
                </FormControl>
                {csvColInfoWithNo[selectColIndex].date_estimation !== true && (
                  <>
                    <FormControl>
                      <FormLabel>日時フォーマット</FormLabel>
                      <Select
                        value={csvColInfoWithNo[selectColIndex].date_format ?? DATE_FORMAT.SLASH}
                        onChange={onChangeList(selectColIndex, "date_format")}
                      >
                        {Object.entries(DATE_FORMAT).map(([key, format]) => (
                          <option key={key} value={key}>
                            {format.displayName}
                          </option>
                        ))}
                      </Select>
                        <FormLabel>
                          サンプル：
                          <Stack spacing={1} pl={4}>
                          {(DATE_FORMAT as Record<string, { sample: string[] }>)[
                            csvColInfoWithNo[selectColIndex].date_format
                          ]?.sample.map((sample, index) => (
                            <div key={index}>{sample}</div>
                          ))}
                        </Stack>
                        </FormLabel>
                    </FormControl>
                    {csvColInfoWithNo[selectColIndex].date_format?.startsWith("ISO") ? null : (
                      <FormControl>
                      <FormLabel>日時パターン</FormLabel>
                      <Select
                        value={csvColInfoWithNo[selectColIndex].date_pattern ?? DATE_PATTERN.Ymd}
                        onChange={onChangeList(selectColIndex, "date_pattern")}
                      >
                        {Object.entries(DATE_PATTERN).map(([key, pattern]) => (
                        <option key={key} value={key}>
                          {pattern.displayName}
                        </option>
                        ))}
                      </Select>
                      </FormControl>
                    )}
                  </>
                )}
              </Stack>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isBulkSettingModalOpen}
        size={"lg"}
        onClose={() => {
          onSubModalReset();
          onBulkSettingModalClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>列名一括設定</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>列名（CSVファイルのヘッダー）を貼り付けてください。</FormLabel>
                <Input
                  placeholder="列1,列2,列3,…"
                  value={bulkColNames}
                  onChange={(e) => setBulkColNames(e.target.value.replace(/\t/g, ","))}
                />
              </FormControl>

              {ngsiConvert && (
                <FormControl>
                  <FormLabel>属性名を貼り付けてください。</FormLabel>
                  <Input
                    placeholder="attribute1,attribute2,attribute3,…"
                    value={bulkColAttributes}
                    onChange={(e) => setBulkColAttributes(e.target.value.replace(/\t/g, ","))}
                  />
                </FormControl>
              )}
              <Flex fontSize={"sm"} ml="2" color={"gray.700"}>
                ※現在のCSV項目設定の内容は上書きされます。
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <PrimaryButton
              onClick={() => {
                if (bulkColNames.trim() === "" && bulkColAttributes.trim() === "") {
                  setCsvColInfoWithNo([]);
                } else {
                  const colNamesArray = bulkColNames.split(",");
                  const colAttributesArray = bulkColAttributes.split(",");
                  const maxLength = Math.max(colNamesArray.length, colAttributesArray.length);
                  setCsvColInfoWithNo(
                    Array.from({ length: maxLength }).map((_, index: number) => ({
                      no: index + 1,
                      name: colNamesArray[index] || "",
                      attribute: colAttributesArray[index] || "",
                      type: "string",
                      isRequired: false,
                      date_estimation: true,
                      date_format: "SLASH",
                      date_pattern: "Ymd",
                    }))
                  );
                }
                onSubModalReset();
                onBulkSettingModalClose();
              }}
            >
              設定
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
