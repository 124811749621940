import { SecurityLevel_V1Type } from "./commonTypes";

export interface CsvImportDef {
    tenant_id: string;
    definition_id: string;
    name: string;
    id: string;
    securityLevel: SecurityLevel_V1Type;
    ngsiConvert: boolean;
    entityTypeName: string;
    cols: CsvColInfo[];
}

export interface CsvColInfo
{
    name: string;
    attribute: string;
    type: "string" | "double" | "timestamp";    // GlueTableのデータ型に合わせる
    isRequired: boolean;
    date_estimation: boolean;
    date_format: keyof typeof DATE_FORMAT;
    date_pattern: keyof typeof DATE_PATTERN;
}

// date_formatの定数（値:{追加情報}）
export const DATE_FORMAT = {
    SLASH: { displayName: "ISO8601以外", sample: ["2025/01/23 01:23:45.123", "2025-01-23 01:23:45.123", "2025.01.23 01:23:45.123", "2025年01月23日 01時23分45.123秒"] },
    // SLASH: { displayName: "スラッシュ区切り", sample: "2025/01/23 01:23:45.123" },
    // HYPHEN: { displayName: "ハイフン区切り", sample: "2025-01-23 01:23:45.123" },
    // DOT: { displayName: "ピリオド区切り", sample: "2025.01.23 01:23:45.123" },
    // KANJI: { displayName: "漢字表記", sample: "2025年01月23日 01時23分45.123秒" },
    ISO_UTC: { displayName: "ISO8601", sample: ["2025-01-23T01:23:45.123Z","2025-01-23T01:23:45.123","2025-01-23T01:23:45.123+09:00"] },
    // ISO_UTC: { displayName: "ISO8601（UTC）", sample: "2025-01-23T01:23:45.123Z" },
    // ISO_NO_TZ: { displayName: "ISO8601（TZ無）", sample: "2025-01-23T01:23:45.123" },
    // ISO_WITH_TZ: { displayName: "ISO8601（TZ有）", sample: "2025-01-23T01:23:45.123+09:00" }
};

// date_patternの定数（値:{追加情報}）
export const DATE_PATTERN = {
    Y: { displayName: "年" },
    Ym: { displayName: "年月" },
    Ymd: { displayName: "年月日" },
    YmdH: { displayName: "年月日時" },
    YmdHM: { displayName: "年月日時分" },
    YmdHMS: { displayName: "年月日時分秒" },
    YmdHMSf: { displayName: "年月日時分秒ミリ秒" }
};