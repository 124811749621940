import { useCallback, useState } from "react";
import axios from "axios";
import { useMessage } from "./useMessage";
import { useLoginUser } from "./useLoginUser";
import appconfig from "../config.json";
import { CsvColInfo, CsvImportDef } from "../types/api/csvImportDef";
import { SecurityLevel_V1Type } from "../types/api/commonTypes";

export const useCsvImportDef = () => {
  const { showMessage } = useMessage();
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [csvImportDefs, setCsvImportDefs] = useState<Array<CsvImportDef>>([]);

  const MANAGER_API_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const csvImportDefAdd = useCallback(
    (
      name: string,
      id: string,
      securityLevel: SecurityLevel_V1Type,
      ngsiConvert: boolean,
      entityTypeName: string,
      cols: CsvColInfo[]
    ) => {
      setLoading(true);

      const tenant_id = selectTenant ?? loginUser?.tenant;
      const definition_id = `${tenant_id}-${id}`;
      const csvImportDef = {
        tenant_id: tenant_id,
        definition_id: definition_id,
        name: name,
        id: id,
        securityLevel: securityLevel,
        ngsiConvert: ngsiConvert,
        entityTypeName: entityTypeName,
        cols: cols,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        let atr: string = "";
        if (selectTenant) {
          atr = "?tenant=" + selectTenant;
        }
        axios
          .post<any>(MANAGER_API_URL + "/csvimport/definitions" + atr, csvImportDef, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "追加しました。", status: "success" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .then(() => {
            getCsvImportDefs();
          })
          .catch((msg) => {
            showMessage({
              title: `CSV取込定義が作成できません。(${msg})`,
              status: "error",
            });
            setLoading(false);
          });
      });
    },
    []
  );

  const csvImportDefEdit = useCallback(
    (
      name: string,
      id: string,
      securityLevel: SecurityLevel_V1Type,
      ngsiConvert: boolean,
      entityTypeName: string,
      cols: CsvColInfo[]
    ) => {
      setLoading(true);
      const tenant_id = selectTenant ?? loginUser?.tenant;
      const definition_id = `${tenant_id}-${id}`;
      const csvImportDef = {
        tenant_id: tenant_id,
        definition_id: definition_id,
        name: name,
        id: id,
        securityLevel: securityLevel,
        ngsiConvert: ngsiConvert,
        entityTypeName: entityTypeName,
        cols: cols,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        let atr: string = "";
        if (selectTenant) {
          atr = "?tenant=" + selectTenant;
        }
        axios
          .put<any>(MANAGER_API_URL + "/csvimport/definitions/" + definition_id + atr, csvImportDef, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "更新しました。", status: "success" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .then(() => {
            getCsvImportDefs();
          })
          .catch((msg) => {
            showMessage({
              title: `CSV取込定義が更新できません。(${msg})`,
              status: "error",
            });
            setLoading(false);
          });
      });
    },
    []
  );

  const csvImportDefDelete = useCallback((id: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      const tenant_id = selectTenant ?? loginUser?.tenant;
      const definition_id = `${tenant_id}-${id}`;
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .delete<any>(MANAGER_API_URL + "/csvimport/definitions/" + definition_id + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status == 204) {
            showMessage({ title: "削除しました。", status: "success" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getCsvImportDefs();
        })
        .catch((msg) => {
          if (msg.response.status === 403) {
            showMessage({ title: "権限エラー:" + msg.response.data, status: "error" });
          } else if (msg.response.status === 409) {
            showMessage({ title: "整合性エラー:" + msg.response.data.message, status: "error" });
          } else {
            showMessage({
              title: `CSV取込定義が削除できません(${msg})`,
              status: "error",
            });
            }
          setLoading(false);
        });
    });
  }, []);

  const getCsvImportDefs = useCallback(() => {

    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .get<Array<CsvImportDef>>(MANAGER_API_URL + "/csvimport/definitions" + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) =>
          setCsvImportDefs(
            res.data.sort((a, b) => {
              if (a.id.toLowerCase() > b.id.toLowerCase()) return 1;
              else return -1;
            })
          )
        )
        .catch(() =>
          showMessage({
            title: "CSV取込定義の取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return { getCsvImportDefs, csvImportDefAdd, csvImportDefEdit, csvImportDefDelete, loading, csvImportDefs };
};
