import { useCallback, useState } from "react";
import axios from "axios";
import { useMessage } from "./useMessage";
import { useLoginUser } from "./useLoginUser";
import appconfig from "../config.json";
import { Execution } from "../types/api/stepFunctions";

export const useStepFunctions = () => {
  const { showMessage } = useMessage();
  const { tokenRefresh, selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [sfnExecutions, setSfnExecutions] = useState<Array<Execution>>([]);
  const WORKFLOW_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const runTruncate = useCallback((entityType: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh()))
      .then((token) => {
        const newDate = new Date();
        const name = entityType + "_" + newDate.getTime();
        let atr: string = `?entitytype=${entityType}&name=${name}`;
        if (selectTenant) {
          atr = atr + "&tenant=" + selectTenant;
        }
        console.log(atr);
        axios
          .get<any>(WORKFLOW_MANAGER_URL + "/execution/truncate" + atr, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setLoading(false);

            if (res.data) {
              console.log(res.data);
              showMessage({
                title: "削除処理を開始しました。",
                status: "success",
              });
              setLoading(false);
            } else {
              showMessage({
                title: "削除処理の開始に失敗しました。",
                status: "error",
              });
              setLoading(false);
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage({
          title: "削除処理の開始に失敗しました。",
          status: "error",
        });
      })
      .finally(() => {
        // setUploading(false);
      });
  }, []);

  const startExecution = useCallback((stateArn: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh()))
      .then((token) => {
        const newDate = new Date();
        let name: string = newDate.getTime().toString();
        if (selectTenant) {
          name = selectTenant + "_" + newDate.getTime();
        }
        const atr: string = `?state=${stateArn}&name=${name}`;
        axios
          .get<any>(WORKFLOW_MANAGER_URL + "/execution/start" + atr, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setLoading(false);

            if (res.data) {
              console.log(res.data);
              showMessage({ title: "処理を開始しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: "処理の開始に失敗しました。", status: "error" });
              setLoading(false);
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage({
          title: "処理の開始に失敗しました。",
          status: "error",
        });
      })
      .finally(() => {
        // setUploading(false);
      });
  }, []);

  const getListTruncats = useCallback(() => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh()))
      .then((token) => {
        axios
          .get<any>(WORKFLOW_MANAGER_URL + "/execution/truncatedlist", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setSfnExecutions(res.data["executions"]);
          })
          .catch((err) => {
            throw new Error(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage({
          title: "削除処理状態の取得に失敗しました。",
          status: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getListExecutions = useCallback((stateArn: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh()))
      .then((token) => {
        const atr: string = `?state=${stateArn}`;
        axios
          .get<any>(WORKFLOW_MANAGER_URL + "/execution/list" + atr, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setSfnExecutions(res.data["executions"]);
          })
          .catch((err) => {
            throw new Error(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage({
          title: "処理状態の取得に失敗しました。",
          status: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    runTruncate,
    getListTruncats,
    startExecution,
    getListExecutions,
    loading,
    sfnExecutions,
  };
};
