// TODO: ★★Workflowからコピーしただけ。必要に応じて修正すること。
export enum JobStatusType {
    RUNNING='RUNNING',
    SUCCEEDED='SUCCEEDED',
    FAILED='FAILED',  
};

export type CsvImportRun = {
    user_id: string, // ログインユーザのID
    name: string, // 取込対象の定義名　※記録用
    id: string, // 取込対象の定義ID　※記録用
    file_name: string, // 取込対象のファイル名　※記録用
    file_bucket: string, // 取込対象のファイルのS3bucket
    file_key: string, // 取込対象のファイルのS3key
};

export type CsvImportStatus = 
{
    name: string, // 定義名
    id: string, // 定義ID（テーブル名）
    file_name: string, // 取込ファイル名
    run_id: string, // 実行ID
    StartedOn: string, // 開始日時
    CompletedOn: string, // 完了日時
    JobRunState: JobStatusType // ステータス
};