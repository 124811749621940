import { ReactNode, FC, ChangeEvent, useState } from "react";
import {
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

type Props = {
    placeholder: string;
    searchValue: string;
    setSearchValue: any;
    maxW?: string;
};

export const SearchInput: FC<Props> = (props) => {
    const { placeholder, setSearchValue, searchValue, maxW = "80vw" } = props;

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    return (
        <Flex mx="5" w="full" maxW={maxW} py="1">
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={<Search2Icon color="gray.300" />}
                />
                <Input
                    value={searchValue}
                    placeholder={`${placeholder}で検索`}
                    onChange={onChangeSearch}
                    fontSize="sm"
                />
            </InputGroup>
        </Flex>
    );
};
