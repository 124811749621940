import { ChangeEvent, memo, useCallback, useEffect, useState, FC, useRef, useMemo } from "react";
import {
    Button,
    Center,
    Flex,
    FlexProps,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Spinner,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Td,
    Th,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";

import { PageDiscription } from "../../atoms/button/PageDiscription";
import { FileRow } from "../../organisms/datarow/FileRow";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { RepeatIcon } from "@chakra-ui/icons";
import { useParameters } from "../../../hooks/useParameters";
import { useCsvImport } from "../../../hooks/useCsvImport";
import appconfig from "../../../config.json";
import { TenantSingleParm } from "../../../types/api/parameterTypes";
import { useCsvImportDef } from "../../../hooks/useCsvImportDef";
import { CsvImportDefRow } from "../../organisms/datarow/CsvImportDefRow";
import { SearchInput } from "../../atoms/Input/SearchInput";

import { FileInfo } from "../../../types/api/file";
import { JobStatusType } from "../../../types/api/csvImport";
import { CsvImportErrorModal } from "../../organisms/modal/CsvImportErrorModal";

export const CsvImports: FC = memo(() => {
    const { isOpen: isDefOpen, onOpen: onDefOpen, onClose: onDefClose } = useDisclosure();
    const { isOpen: isFileOpen, onOpen: onFileOpen, onClose: onFileClose } = useDisclosure();
    const { loginUser, selectTenant } = useLoginUser();
    const {
        sourceLoading,
        dataLakeLoading,
        uploading: uploadLoading,
        loading: fileLoading,
        csvImportStatusLoading,
        jsonContentLoading,
        getSourceFileList,
        getDataLakeFileList,
        putSourceFile,
        deleteFile,
        clearData,
        getFile,
        runCsvImport,
        getCsvImportStatus,
        getCsvImportError,
        sourceFiles,
        dataLakeFiles,
        csvImportStatus,
        jsonContent,
    } = useCsvImport();

    const { getParameter: getPublicBucket, parameter: publicBucket } = useParameters();
    const { getParameter: getPrivateBucket, parameter: privateBucket } = useParameters();
    const { getParameter: getExclusiveBucket, parameter: exclusiveBucket } = useParameters();
    const { getParameter: getPersonalBucket, parameter: personalBucket } = useParameters();
    // const { getParameter: getBinaryBucket, parameter: binaryBucket, loading: binaryLoading } = useParameters();
    const { getParameter: getSourceBucket, parameter: sourceBucket } = useParameters();

    const { getCsvImportDefs, loading: defsLoading, csvImportDefs } = useCsvImportDef();

    const [defSearchValue, setDefSearchValue] = useState("");
    const [fileSearchValue, setFileSearchValue] = useState("");
    const refFileInput = useRef<HTMLInputElement>(null);

    const [running, setRunning] = useState<boolean>(false);

    const [bucket, setBucket] = useState<string | undefined>(undefined);
    const [prefix, setPrefix] = useState<string | undefined>(undefined);

    const didLogRef = useRef(false);
    useEffect(() => {
        if (didLogRef.current === false) {
            didLogRef.current = true;

            // CSV取込定義を取得
            getCsvImportDefs();

            // 各バケット名を取得
            const tenant_id = selectTenant || loginUser?.tenant || "";
            const basePath = "/" + appconfig.IOT_MANAGEMENT_ENV + "/" + tenant_id + "/";

            getPublicBucket(basePath + TenantSingleParm.PublicDataLake);
            getPrivateBucket(basePath + TenantSingleParm.PrivateDataLake);
            getExclusiveBucket(basePath + TenantSingleParm.ExclusiveDataLake);
            getPersonalBucket(basePath + TenantSingleParm.AnonymizedPersonalDataLake);
            // getBinaryBucket(basePath + TenantSingleParm.BinaryDataLake);
            getSourceBucket(basePath + TenantSingleParm.UsersWorkBucket);
        }
    }, []);

    // CSV取込定義の選択を変更したとき
    const onChangeDefId = useCallback(
        (DefId: string) => {
            clearData();
            setSelectDefId(DefId);
            const selectDef = csvImportDefs?.find((def) => def.definition_id === DefId);
            if (selectDef) {
                const dataLakeBucket =
                    selectDef.securityLevel === "public"
                        ? publicBucket
                        : selectDef.securityLevel === "private"
                        ? privateBucket
                        : selectDef.securityLevel === "exclusive"
                        ? exclusiveBucket
                        : selectDef.securityLevel === "a-personal"
                        ? personalBucket
                        : "";
                if (dataLakeBucket) {
                    setBucket(dataLakeBucket);
                    setPrefix(selectDef.id);
                    getDataLakeFileList(dataLakeBucket, selectDef.id);
                }
                if (sourceBucket) {
                    getSourceFileList(sourceBucket, selectDef.id);
                }
                getCsvImportStatus(DefId);
                onDefClose();
            }
        },
        [
            csvImportDefs,
            publicBucket,
            privateBucket,
            exclusiveBucket,
            personalBucket,
            sourceBucket,
            getDataLakeFileList,
            getSourceFileList,
            clearData,
        ]
    );
    
    // JobRunStateがRUNNINGの場合、実行中フラグを立てる
    useEffect(() => {
        if (csvImportStatus?.JobRunState === "RUNNING") {
            setRunning(true);
        } else {
            setRunning(false);
        }
    }, [csvImportStatus]);

    const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
    const [selectDefId, setSelectDefId] = useState<string>("");
    const [csvError, setCsvError] = useState<string | null>(null);

    // ファイル選択
    const getCsvFile = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files) return;
            const file = e.target.files[0];
            if (file.name.length > 100) {
                setCsvError("ファイル名は100文字以内としてください。");
                setCsvFile(undefined);
                if (refFileInput.current) refFileInput.current.value = "";
                return;
            }
            if (file.type !== "text/csv" && !file.name.toLowerCase().endsWith(".csv")) {
                setCsvError("CSVファイルを選択してください。");
                setCsvFile(undefined);
                if (refFileInput.current) refFileInput.current.value = "";
            } else {
                setCsvError(null);
                setCsvFile(file);
            }
        },
        [setCsvError, setCsvFile, refFileInput]
    );

    // ファイルアップロード
    const onClickFileUpload = useCallback(() => {
        const id = csvImportDefs.find((def) => def.definition_id === selectDefId)?.id;

        if (sourceBucket && id && csvFile) {
            putSourceFile(sourceBucket, id, csvFile);
            if (refFileInput?.current) refFileInput.current.value = "";
            setCsvFile(undefined);

            onFileClose();
        }
    }, [sourceBucket, selectDefId, csvFile, onFileClose]);

    // ファイルダウンロード
    const onClickFileDownload = useCallback((bucket: string | undefined, key: string) => {
        if (bucket && key) {
            getFile(bucket, key);
        }
    }, []);

    // ファイル削除
    const onClickFileDelete = useCallback(
        (
            bucket: string | undefined,
            prefix: string | undefined,
            key: string,
            isSource: boolean
        ) => {
            if (bucket && prefix && key) {
                if (window.confirm("本当に削除しますか？")) {
                    deleteFile(bucket, prefix, key, isSource);
                }
            }
        },
        []
    );

    // 更新ボタン
    const onClickReload = useCallback(() => {
        if (bucket && prefix) {
            getDataLakeFileList(bucket, prefix);
        }
        if (sourceBucket && prefix) {
            getSourceFileList(sourceBucket, prefix);
        }
        getCsvImportStatus(selectDefId);
    }, [
        getDataLakeFileList,
        getSourceFileList,
        getCsvImportStatus,
        selectDefId,
        bucket,
        prefix,
        sourceBucket,
    ]);

    // ソート処理
    const [sortConfig, setSortConfig] = useState<{
        key: keyof FileInfo;
        direction: string;
    } | null>(null);
    const handleSort = (key: string) => {
        let direction = "asc";
        if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key: key as keyof FileInfo, direction });
    };
    const sortedFiles = useMemo(() => {
        if (sortConfig !== null) {
            return [...sourceFiles].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "asc" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return sourceFiles;
    }, [sourceFiles, sortConfig]);
    const sortedDataLakeFiles = useMemo(() => {
        if (sortConfig !== null) {
            return [...dataLakeFiles].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "asc" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return dataLakeFiles;
    }, [dataLakeFiles, sortConfig]);

    // CSV取込処理の実行
    const onClickRunCsvImport = useCallback(
        (fileKey: string) => {
            const selectedDef = csvImportDefs.find((def) => def.definition_id === selectDefId);
            if (sourceBucket && selectedDef && fileKey && bucket) {
                if (window.confirm("CSV取込処理を実行しますか？")) {
                    runCsvImport(
                        selectedDef.definition_id,
                        selectedDef.name,
                        selectedDef.id,
                        sourceBucket,
                        fileKey,
                        bucket
                    );
                    // 実行中フラグを立てる
                    setRunning(true);
                }
            }
        },
        [csvImportDefs, selectDefId, sourceBucket, bucket, runCsvImport]
    );

    // エラーモーダル表示
    const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure();
    const onClickShowError = useCallback(() => {
        if (sourceBucket && prefix) {
            getCsvImportError(sourceBucket, prefix);
        }
        onErrorOpen();
    }, [sourceBucket, prefix, getCsvImportError, onErrorOpen]);

    // CSV取込定義選択のモーダル
    const renderDefModal = useCallback(() => {
        return (
            <Modal size={"2xl"} isOpen={isDefOpen} onClose={onDefClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>CSV定義取込を選択</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            {defsLoading ? (
                                <Spinner color="teal.500" />
                            ) : (
                                <Stack>
                                    <SearchInput
                                        placeholder={"定義ID、定義名、エンティティタイプ名で検索"}
                                        searchValue={defSearchValue}
                                        setSearchValue={setDefSearchValue}
                                    />
                                    <TableContent
                                        heightOffset="-100px"
                                        small={true}
                                        headerRows={
                                            <Tr key="header">
                                                <Th w="100vw">定義ID</Th>
                                                <Th w="100vw">定義名</Th>
                                                <Th w="200px">セキュリティレベル</Th>
                                                <Th w="150px">FIWARE登録</Th>
                                                <Th w="100vw">エンティティタイプ名</Th>
                                            </Tr>
                                        }
                                        bodyRows={csvImportDefs
                                            .filter((obj) => {
                                                return (
                                                    defSearchValue === "" ||
                                                    obj.id.indexOf(defSearchValue) >= 0 ||
                                                    obj.name.indexOf(defSearchValue) >= 0 ||
                                                    obj.entityTypeName.indexOf(defSearchValue) >= 0
                                                );
                                            })
                                            .map((obj) => (
                                                <CsvImportDefRow
                                                    key={obj.definition_id}
                                                    definition_id={obj.definition_id}
                                                    name={obj.name}
                                                    id={obj.id}
                                                    securityLevel={obj.securityLevel}
                                                    ngsiConvert={obj.ngsiConvert}
                                                    entityTypeName={obj.entityTypeName}
                                                    onSelect={onChangeDefId}
                                                    selectedDefId={selectDefId}
                                                />
                                            ))}
                                    ></TableContent>
                                </Stack>
                            )}
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }, [
        isDefOpen,
        onDefClose,
        defsLoading,
        defSearchValue,
        csvImportDefs,
        selectDefId,
        onChangeDefId,
        useDisclosure,
    ]);

    // ファイルアップロードのモーダル
    const renderFileUploadModal = useCallback(() => {
        return (
            <Modal
                size={"2xl"}
                isOpen={isFileOpen}
                onClose={() => {
                    onFileClose();
                    setCsvFile(undefined);
                    setCsvError(null);
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>ファイルアップロード</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack my="2" h="10">
                            <Input
                                id="file"
                                ref={refFileInput}
                                type="file"
                                accept=".csv"
                                onChange={getCsvFile}
                                disabled={uploadLoading || !selectDefId || !sourceBucket}
                                sx={{
                                    "::file-selector-button,::-webkit-file-upload-button": {
                                        backgroundColor: "rgb(209, 213, 219)",
                                        color: "rgb(31, 41, 55)",
                                        border: "none",
                                        cursor: "pointer",
                                        br: "1px solid rgb(191, 194, 199)",
                                        padding: "0.25rem 1rem",
                                        mr: "1rem",
                                        height: "100%",
                                    },
                                }}
                                bg={"gray.50"}
                                size={{ sm: "sm", md: "md" }}
                            />
                            <FormControl isInvalid={!!csvError}>
                                <FormErrorMessage>{csvError}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex
                            px={{ base: 4, md: 10 }}
                            py={{ base: 1, md: 2 }}
                            mt={{ base: 1, md: 2 }}
                        >
                            <Button
                                colorScheme="teal"
                                variant="solid"
                                size={{ base: "sm", md: "md" }}
                                isLoading={uploadLoading}
                                disabled={
                                    uploadLoading || !selectDefId || !sourceBucket || !csvFile
                                }
                                onClick={onClickFileUpload}
                            >
                                アップロード
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }, [isFileOpen, onFileClose, getCsvFile, csvError, uploadLoading, csvFile, onClickFileUpload]);

    const FlexDesigned = memo(
        ({ children, ...props }: { children: React.ReactNode } & FlexProps) => (
            <Flex
                align="center"
                bg="white"
                p={2}
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.200"
                minH="2.5rem"
                {...props}
            >
                {children}
            </Flex>
        )
    );

    // メインの画面
    return (
        <>
            <Stack maxW={"1200px"}>
                <PageDiscription>
                    CSV取込定義を選択し、CSVファイルのアップロードとデータ取込処理を実行してください。
                </PageDiscription>
                <HStack justify="center" mb={4}>
                    <Flex>
                        <Button colorScheme="teal" onClick={onDefOpen}>
                            CSV取込定義選択
                        </Button>
                    </Flex>
                    <FormControl w="30%" mx={2}>
                        <FlexDesigned>{prefix}</FlexDesigned>
                    </FormControl>
                    <FormControl w="70%">
                        <FlexDesigned>
                            {csvImportDefs.find((def) => def.definition_id === selectDefId)?.name ||
                                ""}
                        </FlexDesigned>
                    </FormControl>
                </HStack>

                <Tabs>
                    <TabList>
                        <Tab>アップロードファイル</Tab>
                        <Tab>取込処理状況</Tab>
                        <Tab>データレイク格納ファイル</Tab>
                        <Spacer />
                        <Button
                            leftIcon={<RepeatIcon />}
                            colorScheme="teal"
                            variant="solid"
                            onClick={onClickReload}
                            size={{ base: "sm", md: "md" }}
                            my="2"
                        >
                            更新
                        </Button>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <FormControl>
                                <HStack spacing={4}>
                                    <SearchInput
                                        placeholder={"ファイル名"}
                                        searchValue={fileSearchValue}
                                        setSearchValue={setFileSearchValue}
                                        maxW={"50%"}
                                    />
                                    <Button
                                        colorScheme="teal"
                                        variant="solid"
                                        onClick={onFileOpen}
                                        size={{ base: "sm", md: "md" }}
                                        disabled={!selectDefId || !sourceBucket}
                                    >
                                        アップロード
                                    </Button>
                                </HStack>
                            </FormControl>
                            {fileLoading || uploadLoading || sourceLoading ? (
                                <Center h="50vh">
                                    <Spinner color="teal.500" />
                                </Center>
                            ) : (
                                <>
                                    <TableContent
                                        heightOffset="-100px"
                                        headerRows={
                                            <Tr key="header">
                                                {[
                                                    "ファイル名",
                                                    "アップロード日時",
                                                    "ファイルサイズ",
                                                ].map((label, index) => {
                                                    const key = ["Key", "LastModified", "Size"][
                                                        index
                                                    ];
                                                    return (
                                                        <Th
                                                            key={key}
                                                            onClick={() => handleSort(key)}
                                                            cursor="pointer"
                                                        >
                                                            {label}
                                                            {sortConfig?.key === key
                                                                ? sortConfig.direction === "asc"
                                                                    ? "▲"
                                                                    : "▼"
                                                                : ""}
                                                        </Th>
                                                    );
                                                })}
                                                <Th w="100px" textAlign={"center"}>
                                                    削除
                                                </Th>
                                                <Th w="100px" textAlign={"center"}>
                                                    取込実行
                                                </Th>
                                            </Tr>
                                        }
                                        bodyRows={sortedFiles
                                            .filter((obj) => {
                                                return (
                                                    fileSearchValue === "" ||
                                                    decodeURIComponent(
                                                        obj.Key.split(/[/]+/).pop() || ""
                                                    ).indexOf(fileSearchValue) >= 0
                                                );
                                            })
                                            .filter((obj) => !obj.Key.endsWith("result.json"))
                                            .map((obj) => (
                                                <FileRow
                                                    key={obj.Key}
                                                    bucket={sourceBucket ?? ""}
                                                    prefix={prefix ?? ""}
                                                    filekey={obj.Key}
                                                    lastModified={obj.LastModified}
                                                    size={obj.Size}
                                                    onClickDelete={(bucket, prefix, key) =>
                                                        onClickFileDelete(bucket, prefix, key, true)
                                                    }
                                                    onClickGet={onClickFileDownload}
                                                    onClickRun={() => onClickRunCsvImport(obj.Key)}
                                                    disabled={running}
                                                />
                                            ))}
                                    ></TableContent>
                                </>
                            )}
                        </TabPanel>
                        <TabPanel>
                            {csvImportStatusLoading ? (
                                <Center h="50vh">
                                    <Spinner color="teal.500" />
                                </Center>
                            ) : (
                                <Stack spacing={4} p={4}>
                                    <FormControl>
                                        <HStack spacing={4}>
                                            <FormLabel>取込処理状況（最新1件）</FormLabel>
                                            <Spacer />
                                        </HStack>
                                    </FormControl>
                                    <HStack spacing={4}>
                                        <FormControl>
                                            <FormLabel>開始日時</FormLabel>
                                            <FlexDesigned>
                                                {csvImportStatus?.StartedOn
                                                    ? new Date(
                                                          csvImportStatus.StartedOn
                                                      ).toLocaleString("ja-JP")
                                                    : ""}
                                            </FlexDesigned>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>終了日時</FormLabel>
                                            <FlexDesigned>
                                                {csvImportStatus?.CompletedOn
                                                    ? new Date(
                                                          csvImportStatus.CompletedOn
                                                      ).toLocaleString("ja-JP")
                                                    : ""}
                                            </FlexDesigned>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>実行状態</FormLabel>
                                            <FlexDesigned>
                                                {csvImportStatus?.JobRunState === "RUNNING"
                                                    ? "実行中"
                                                    : csvImportStatus?.JobRunState
                                                    ? "完了"
                                                    : ""}
                                            </FlexDesigned>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>取込結果</FormLabel>
                                            <HStack>
                                                <FlexDesigned
                                                    color={
                                                        csvImportStatus?.JobRunState ===
                                                        JobStatusType.SUCCEEDED
                                                            ? "green.500"
                                                            : "red.500"
                                                    }
                                                    w="100%"
                                                >
                                                    {csvImportStatus?.JobRunState ===
                                                    JobStatusType.SUCCEEDED
                                                        ? "成功"
                                                        : csvImportStatus?.JobRunState ===
                                                              JobStatusType.FAILED && "エラー"}
                                                </FlexDesigned>
                                                {csvImportStatus?.JobRunState === JobStatusType.FAILED && (
                                                    <Button
                                                        onClick={onClickShowError}
                                                        variant="outline"
                                                        colorScheme="red"
                                                        size={"sm"}
                                                        aria-label="詳細"
                                                        ml="3"
                                                    >
                                                        詳細
                                                    </Button>
                                                )}
                                            </HStack>
                                        </FormControl>
                                    </HStack>
                                    <HStack spacing={4}>
                                        <FormControl>
                                            <FormLabel>取込ファイル名</FormLabel>
                                            <FlexDesigned>
                                                {csvImportStatus?.file_name}
                                            </FlexDesigned>
                                        </FormControl>
                                    </HStack>
                                </Stack>
                            )}
                        </TabPanel>
                        <TabPanel>
                            <HStack spacing={4}>
                                <SearchInput
                                    placeholder={"ファイル名"}
                                    searchValue={fileSearchValue}
                                    setSearchValue={setFileSearchValue}
                                    maxW={"50%"}
                                />
                                <Spacer />
                            </HStack>
                            {fileLoading || dataLakeLoading ? (
                                <Center h="50vh">
                                    <Spinner color="teal.500" />
                                </Center>
                            ) : (
                                <>
                                    <TableContent
                                        heightOffset="-100px"
                                        headerRows={
                                            <Tr key="header">
                                                {["ファイル名", "更新日時", "ファイルサイズ"].map(
                                                    (label, index) => {
                                                        const key = ["Key", "LastModified", "Size"][
                                                            index
                                                        ];
                                                        return (
                                                            <Th
                                                                key={key}
                                                                onClick={() => handleSort(key)}
                                                                cursor="pointer"
                                                            >
                                                                {label}
                                                                {sortConfig?.key === key
                                                                    ? sortConfig.direction === "asc"
                                                                        ? "▲"
                                                                        : "▼"
                                                                    : ""}
                                                            </Th>
                                                        );
                                                    }
                                                )}
                                                <Th w="100px" textAlign={"center"}>
                                                    削除
                                                </Th>
                                            </Tr>
                                        }
                                        bodyRows={sortedDataLakeFiles
                                            .filter((obj) => {
                                                return (
                                                    fileSearchValue === "" ||
                                                    decodeURIComponent(
                                                        obj.Key.split(/[/]+/).pop() || ""
                                                    ).indexOf(fileSearchValue) >= 0
                                                );
                                            })
                                            .map((obj) => (
                                                <FileRow
                                                    key={obj.Key}
                                                    bucket={bucket ?? ""}
                                                    prefix={prefix ?? ""}
                                                    filekey={obj.Key}
                                                    lastModified={obj.LastModified}
                                                    size={obj.Size}
                                                    onClickGet={onClickFileDownload}
                                                    onClickDelete={(bucket, prefix, key) =>
                                                        onClickFileDelete(
                                                            bucket,
                                                            prefix,
                                                            key,
                                                            false
                                                        )
                                                    }
                                                />
                                            ))}
                                    ></TableContent>
                                </>
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Stack>
            {renderDefModal()}
            {renderFileUploadModal()}
            <CsvImportErrorModal
                ErrorFiles={jsonContent}
                isOpen={isErrorOpen}
                onClose={onErrorClose}
                loading={jsonContentLoading}
            />
        </>
    );
});
