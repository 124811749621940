import { memo, useCallback, useEffect, useState, FC, useRef } from "react";
import { Center, HStack, Spinner, Stack, Th, Tr, useDisclosure } from "@chakra-ui/react";

import { useCsvImportDef } from "../../../hooks/useCsvImportDef";
import { CsvImportDefModal } from "../../organisms/modal/CsvImportDefModal";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { SearchInput } from "../../atoms/Input/SearchInput";
import { TableContent } from "../../organisms/layout/TableContent";
import { CsvImportDefRow } from "../../organisms/datarow/CsvImportDefRow";
import { CsvImportDef } from "../../../types/api/csvImportDef";
import { useParameters } from "../../../hooks/useParameters";
import { useTenant } from "../../../hooks/useTenant";
import { useLoginUser } from "../../../hooks/useLoginUser";

export const CsvImportDefs: FC = memo(() => {
  const { loginUser, selectTenant } = useLoginUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getCsvImportDefs, csvImportDefAdd, csvImportDefEdit, csvImportDefDelete, loading, csvImportDefs } =
    useCsvImportDef();

  const { getTypeNames, typeNames } = useParameters();
  const { tenantGet, tenant } = useTenant();

  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      getCsvImportDefs();
    }
  }, []);

  // csvImportDefsの内容が変わったら、エンティティタイプ名を再取得
  useEffect(() => {
    let tenant_id: string = "";
    if (selectTenant) {
      tenant_id = selectTenant;
    } else {
      if (loginUser) tenant_id = loginUser.tenant;
    }

    getTypeNames();
    tenantGet(tenant_id);
  }, [csvImportDefs]);


  const newCsvImportDef: CsvImportDef = {
    tenant_id: "",
    definition_id: "",
    name: "",
    id: "",
    securityLevel: "public",
    ngsiConvert: false,
    entityTypeName: "",
    cols: [],
  };
  const [selectedCsvImportDef, setSelectedCsvImportDef] = useState<CsvImportDef>(newCsvImportDef);
  const [searchValue, setSearchValue] = useState("");

  const onClickCsvImportDef = useCallback(
    (definition_id: string) => {
      const targetCsvImportDef = csvImportDefs.find((obj) => obj.definition_id === definition_id);
      if (targetCsvImportDef) {
        setSelectedCsvImportDef(targetCsvImportDef);
        onOpen();
      } else {
        setSelectedCsvImportDef(newCsvImportDef);
        onOpen();
      }
    },
    [newCsvImportDef, onOpen]
  );

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>CSV取込定義を選択してください。</PageDiscription>
      <HStack>
        <SearchInput
          placeholder={"定義ID、定義名、エンティティタイプ名で検索"}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <AddButton onClick={() => onClickCsvImportDef("")} />
      </HStack>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <TableContent
            headerRows={
              <Tr key="header">
                <Th w="100vw">定義ID</Th>
                <Th w="100vw">定義名</Th>
                <Th w="200px">セキュリティレベル</Th>
                <Th w="150px">FIWARE登録</Th>
                <Th w="80vw">エンティティタイプ名</Th>
              </Tr>
            }
            bodyRows={csvImportDefs
              .filter((obj) => {
                return (
                  searchValue === "" ||
                  obj.id.indexOf(searchValue) >= 0 ||
                  obj.name.indexOf(searchValue) >= 0 ||
                  obj.entityTypeName.indexOf(searchValue) >= 0
                );
              })
              .map((obj) => (
                <CsvImportDefRow
                  key={obj.definition_id}
                  definition_id={obj.definition_id}
                  name={obj.name}
                  id={obj.id}
                  securityLevel={obj.securityLevel}
                  ngsiConvert={obj.ngsiConvert}
                  entityTypeName={obj.entityTypeName}
                  onClick={onClickCsvImportDef}
                />
              ))}
          ></TableContent>
        </>
      )}
      <CsvImportDefModal
        isOpen={isOpen}
        onInsert={csvImportDefAdd}
        onUpdate={csvImportDefEdit}
        onDelete={csvImportDefDelete}
        onClose={onClose}
        csvImportDef={selectedCsvImportDef}
        existingIds={csvImportDefs.map((def) => def.id)}
        typeNames={typeNames}
        tenantEntityTypes={tenant?.types ?? []}
      />
    </Stack>
  );
});
