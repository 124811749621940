import { useCallback, useState } from "react";
import axios from "axios";
import { Filelist, FileInfo, OpenSearchErrorFiles } from "../types/api/file";
import { useMessage } from "./useMessage";
import { useLoginUser } from "./useLoginUser";
import appconfig from "../config.json";
import { CsvImportRun, CsvImportStatus } from "../types/api/csvImport";

export const useCsvImport = () => {
    const { showMessage } = useMessage();
    const { tokenRefresh, selectTenant, loginUser } = useLoginUser();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [sourceLoading, setSourceLoading] = useState(false);
    const [dataLakeLoading, setDataLakeLoading] = useState(false);

    const [sourceFiles, setSourceFiles] = useState<Array<FileInfo>>([]);
    const [dataLakeFiles, setDataLakeFiles] = useState<Array<FileInfo>>([]);
    const [csvImportStatus, setCsvImportStatus] = useState<CsvImportStatus>();

    const [jsonContent, setJsonContent] = useState<any>();

    const MANAGER_API_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

    const getSourceFileList = useCallback((bucket: string, id: string) => {
        setSourceLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            let atr: string = "";
            if (selectTenant) {
                atr = "?tenant=" + selectTenant;
            }
            axios
                .get<Filelist>(MANAGER_API_URL + "/s3action/list" + atr, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        bucket: `${bucket}`,
                        key: `${loginUser?.userName}/${id}/`,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setSourceFiles(res.data.Contents);
                    } else if (res.status === 403) {
                        showMessage({ title: "権限エラー:" + res.data, status: "error" });
                        setSourceLoading(false);
                    } else {
                        throw new Error(res.statusText);
                    }
                })
                .catch(() => {
                    showMessage({
                        title: "ファイルリストの取得に失敗しました",
                        status: "error",
                    })
                })
                .finally(() => setSourceLoading(false));
        });
    }, []);

    const getDataLakeFileList = useCallback((bucket: string, prefix: string) => {
        setDataLakeLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            let atr: string = "";
            if (selectTenant) {
                atr = "?tenant=" + selectTenant;
            }
            axios
                .get<Filelist>(MANAGER_API_URL + "/s3action/list" + atr, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        bucket: encodeURIComponent(bucket),
                        key: encodeURIComponent(`data/csv/${prefix}/`),
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setDataLakeFiles(res.data.Contents);
                    } else if (res.status === 403) {
                        showMessage({ title: "権限エラー:" + res.data, status: "error" });
                        setDataLakeLoading(false);
                    } else {
                        throw new Error(res.statusText);
                    }
                })
                .catch(() => {
                    showMessage({
                        title: "ファイルリストの取得に失敗しました",
                        status: "error",
                    })
                })
                .finally(() => setDataLakeLoading(false));
        });
    }, []);

    const clearData = useCallback(() => {
        setSourceFiles([]);
        setDataLakeFiles([]);
        setCsvImportStatus(undefined);
        setJsonContent(undefined);
    }, []);

    const putSourceFile = useCallback((bucket: string, prefix: string, file: File) => {
        setUploading(true);
        const user = loginUser?.userName;

        if (!user) {
            console.log("user is undefined");
            showMessage({
                title: "ファイルアップロードに失敗しました。",
                status: "error",
            });
            setUploading(false);
            return;
        }

        new Promise((resolve) => resolve(tokenRefresh()))
            .then((token) => {
                let atr: string = "";
                if (selectTenant) {
                    atr = "?tenant=" + selectTenant;
                }
                axios
                    .put<any>(
                        MANAGER_API_URL + "/s3action" + atr,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                bucket: encodeURIComponent(bucket),
                                key: encodeURIComponent(`${user}/${prefix}/${file.name}`),
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data) {
                            return res.data.presigned_url;
                        } else {
                            return "";
                        }
                    })
                    .then((url) => {
                        if (url === "") {
                            throw new Error("presigned_url undefined");
                        } else {
                            axios
                                .put<any>(`${url}`, file, { timeout: 0 })
                                .then((res) => {
                                    setUploading(false);
                                    getSourceFileList(bucket, prefix);
                                    showMessage({ title: "ファイルをアップロードしました。", status: "success" });
                                })
                                .catch((err) => {
                                    setUploading(false);
                                    console.log(err);
                                    showMessage({
                                        title: "ファイルアップロードに失敗しました。",
                                        status: "error",
                                    });
                                })
                        }
                    })
                    .catch((err) => {
                        setUploading(false);
                        console.log(err);
                        showMessage({
                            title: "ファイルアップロードに失敗しました。",
                            status: "error",
                        });
                        throw new Error(err);
                    })
            })
            .catch((err) => {
                setUploading(false);
                console.log(err);
                showMessage({
                    title: "ファイルアップロードに失敗しました。",
                    status: "error",
                });
            })
            .finally(() => {
                // setUploading(false);
            })

    }, []);

    const getFile = useCallback((bucket: string, key: string, fileError: boolean = false) => {
        setJsonContent(undefined);

        new Promise((resolve) => resolve(tokenRefresh()))
            .then((token) => {
                let atr: string = "";
                if (selectTenant) {
                    atr = "?tenant=" + selectTenant;
                }
                axios
                    .get<any>(
                        MANAGER_API_URL + "/s3action" + atr,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                bucket: encodeURIComponent(bucket),
                                key: encodeURIComponent(key),
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data) {
                            return res.data.presigned_url;
                        } else {
                            return "";
                        }
                    })
                    .then((url) => {
                        if (url === "") {
                            throw new Error("presigned_url undefined");
                        } else {
                            if (fileError) {
                                // ファイルの中身の取り出し
                                fetch(url)
                                    .then(res => {
                                        if (!res.ok) {
                                            throw new Error(res.statusText)
                                        }
                                        return res.json();
                                    })
                                    .then(json => {
                                        setJsonContent({ status: 200, statusText: "", errorFiles: json } as OpenSearchErrorFiles);
                                    })
                                    .catch(e => {
                                        console.log(e);
                                        setJsonContent({ status: 500, statusText: String(e) } as OpenSearchErrorFiles);
                                    });
                            } else {
                                // ファイルのダウンロード
                                window.open(url, "_self");
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        showMessage({
                            title: "ファイルのダウンロードに失敗しました。",
                            status: "error",
                        });
                        throw new Error(err);
                    })
            })
            .catch((err) => {
                console.log(err);
                showMessage({
                    title: "ファイルのダウンロードに失敗しました。",
                    status: "error",
                });
            })
            .finally(() => {
                // setUploading(false);
            })
    }, []);

    const deleteFile = useCallback((bucket: string, prefix: string, key: string, isSource: boolean) => {
        setLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            let atr: string = "";
            if (selectTenant) {
                atr = "?tenant=" + selectTenant;
            }
            axios
                .delete<Filelist>(MANAGER_API_URL + "/s3action" + atr, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        bucket: encodeURIComponent(bucket),
                        key: encodeURIComponent(key),
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                        showMessage({ title: "ファイルを削除しました。", status: "success" });
                        if (isSource) {
                            getSourceFileList(bucket, prefix);
                        } else {
                            getDataLakeFileList(bucket, prefix);
                        }
                    } else if (res.status === 403) {
                        setLoading(false);
                        showMessage({ title: "権限エラー:" + res.data, status: "error" });
                    } else {
                        throw new Error(res.statusText);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    showMessage({
                        title: "ファイルの削除に失敗しました。",
                        status: "error",
                    });
                })
                .finally(() => setLoading(false));
        });
    }, []);

    // 取込処理の実行
    const runCsvImport = useCallback((definition_id: string, name: string, id: string, bucket: string, key: string, data_bucket: string) => {
        setLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            axios
                .post<CsvImportRun>(
                    `${MANAGER_API_URL}/csvimport/job/${definition_id}/start`,
                    {
                        name: name,
                        id: id,
                        file_name: key.split('/').pop(),
                        file_bucket: bucket,
                        file_key: key,
                        data_bucket: data_bucket,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    setLoading(false);
                    getCsvImportStatus(definition_id);
                    showMessage({ title: "取込処理を開始しました。", status: "success" });
                })
                .catch((err) => {
                    console.log(err);
                    showMessage({
                        title: "取込処理の開始に失敗しました。",
                        status: "error",
                    });
                    setLoading(false);
                });
        });
    }, []);

    // 取込処理のステータスを取得
    const [csvImportStatusLoading, setCsvImportStatusLoading] = useState(false);

    const getCsvImportStatus = useCallback((definition_id: string) => {
        setCsvImportStatusLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            axios
                .get<CsvImportStatus>(
                    `${MANAGER_API_URL}/csvimport/job/${definition_id}/status`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                )
                .then((res) => {
                    setCsvImportStatus(res.data);
                    setCsvImportStatusLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    showMessage({
                        title: "取込処理のステータスの取得に失敗しました。",
                        status: "error",
                    });
                    setCsvImportStatusLoading(false);
                });
        });
    }, []);

    // 取込処理のエラーを取得
    const [jsonContentLoading, setJsonContentLoading] = useState(false);

    const getCsvImportError = useCallback((bucket: string, prefix: string) => {
        setJsonContentLoading(true);
        setJsonContent(undefined);
        new Promise((resolve) => resolve(tokenRefresh()))
            .then((token) => {
                const key = `${loginUser?.userName}/${prefix}/result/result.json`;
                axios
                    .get<any>(
                        `${MANAGER_API_URL}/s3action`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                bucket: encodeURIComponent(bucket), // Replace with your bucket name
                                key: encodeURIComponent(key),
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data) {
                            return res.data.presigned_url;
                        } else {
                            return "";
                        }
                    })
                    .then((url) => {
                        if (url === "") {
                            throw new Error("presigned_url undefined");
                        } else {
                            fetch(url)
                                .then((res) => {
                                    if (!res.ok) {
                                        throw new Error(res.statusText);
                                    }
                                    return res.json();
                                })
                                .then((json) => {
                                    setJsonContent({ status: 200, statusText: "", errorFiles: json } as OpenSearchErrorFiles);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setJsonContent({ status: 500, statusText: String(e) } as OpenSearchErrorFiles);
                                });
                        }
                        setJsonContentLoading(false)
                    })
                    .catch((err) => {
                        console.log(err);
                        setJsonContentLoading(false)
                        showMessage({
                            title: "エラーの取得に失敗しました。",
                            status: "error",
                        });
                        throw new Error(err);
                    });
                })
            .catch((err) => {
                console.log(err);
                setJsonContentLoading(false)
                showMessage({
                    title: "エラーの取得に失敗しました。",
                    status: "error",
                });
            })
    }, []);

    return {
        getSourceFileList,
        getDataLakeFileList,
        clearData,
        putSourceFile,
        getFile,
        deleteFile,
        runCsvImport,
        getCsvImportStatus,
        getCsvImportError,
        sourceFiles,
        dataLakeFiles,
        csvImportStatus,
        loading,
        uploading,
        sourceLoading,
        dataLakeLoading,
        csvImportStatusLoading,
        jsonContentLoading,
        jsonContent
    };
};
