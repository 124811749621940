import { memo, FC } from "react";
import { Button, Link, Td, Tr } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { SecurityLevelPtn } from "../../../types/api/commonTypes";

type Props = {
    definition_id: string;
    name: string;
    id: string;
    securityLevel: string;
    ngsiConvert: boolean;
    entityTypeName: string;
    onClick?: (definition_id: string) => void;
    onSelect?: (definition_id: string) => void;
    selectedDefId?: string;
};

export const CsvImportDefRow: FC<Props> = memo((props) => {
    const {
        definition_id,
        name,
        id,
        securityLevel,
        ngsiConvert,
        entityTypeName,
        onClick,
        onSelect,
        selectedDefId,
    } = props;

    // 選択可能なセキュリティレベルの定数と表示名称
    const securityLevelOptions = [
        { value: SecurityLevelPtn.Public, label: "IoT(public)" },
        { value: SecurityLevelPtn.Private, label: "IoT(private)" },
        { value: SecurityLevelPtn.Exclusive, label: "IoT(exclusive)" },
        { value: SecurityLevelPtn.Anonymized, label: "匿名加工情報" },
    ];

    return (
        <Tr
            key={definition_id}
            bg={selectedDefId === definition_id ? "blue.100" : "gray.50"}
            onClick={onSelect ? () => onSelect(definition_id) : undefined}
            cursor={onSelect ? "pointer" : "default"}
        >
            <Td>
                {onClick ? (
                    <Link
                        onClick={(e) => {
                            e.stopPropagation();
                            onClick(definition_id);
                        }}
                        isExternal
                    >
                        {id}
                        <EditIcon mx="2" color={"gray"} fontSize="sm" />
                    </Link>
                ) : (
                    id
                )}
            </Td>
            <Td>{name}</Td>
            <Td>
                {securityLevelOptions.find(
                    (option) => option.value === securityLevel
                )?.label || securityLevel}
            </Td>
            <Td>{ngsiConvert ? "有効" : "無効"}</Td>
            <Td>{entityTypeName}</Td>
        </Tr>
    );
});
