import { memo, ReactNode, FC } from "react";
import {
    Table,
    TableContainer,
    TableContainerProps,
    Tbody,
    Thead,
} from "@chakra-ui/react";
import "../../../index.css";

interface Props extends TableContainerProps {
    headerRows: ReactNode;
    bodyRows: ReactNode;
    heightOffset?: string;
    small?: boolean;
}

export const TableContent: FC<Props> = memo((props) => {
    const {
        headerRows,
        bodyRows,
        heightOffset = "0px",
        small = false,
        ...react
    } = props;

    return (
        <TableContainer
            overflowY="unset"
            m={{ base: "3", md: "5" }}
            borderWidth="1px"
            borderColor="gray.300"
            bg="gray.200"
            h={`calc(100vh - 300px + ${heightOffset})`}
            maxH="full"
            minH="240px"
            {...react}
        >
            <Table
                variant="simple"
                size={
                    small ? { base: "xs", md: "sm" } : { base: "sm", md: "md" }
                }
                className="main-table"
            >
                <Thead
                    position="sticky"
                    zIndex="1"
                    bg="teal.500"
                    color="gray.50"
                    top={0}
                >
                    {headerRows}
                </Thead>
                <Tbody maxH="100%">{bodyRows}</Tbody>
            </Table>
        </TableContainer>
    );
});
